/* main app css */
@import url("~remixicon/fonts/remixicon.css");
/* @import url('~react-phone-input-2/lib/style.css'); */

@tailwind base;
@tailwind components;
@tailwind utilities;


*:focus {
    outline: none;
}


button,
a {
    transition: all 200ms;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid theme('colors.gray.200');
    -webkit-text-fill-color: theme('colors.gray.700');
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}



.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
    border: 1px solid theme('colors.gray.700');
    -webkit-text-fill-color: theme('colors.gray.300');
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}



input.appearance-none::-webkit-outer-spin-button,
input.appearance-none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.login-page {
    background-image: url("data:image/svg+xml,%3Csvg width='1280' height='569' viewBox='0 0 1280 569' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1004_16' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='1280' height='569'%3E%3Crect width='1280' height='569' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1004_16)'%3E%3Ccircle cx='1202.5' cy='484.5' r='47.5' stroke='%232478FF08' stroke-width='24'/%3E%3Ccircle cx='1224' cy='313' r='12' stroke='%232478FF08' stroke-width='8'/%3E%3Ccircle cx='1123.5' cy='394.5' r='14.5' stroke='%232478FF08' stroke-width='10'/%3E%3Ccircle cx='196' cy='60' r='38' stroke='%232478FF08' stroke-width='18'/%3E%3Ccircle cx='-67' cy='357' r='267' stroke='%232478FF08' stroke-width='66'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center bottom;
    background-size: 100%;
}

table.align-top th,
table.align-top td {
    vertical-align: top;
}


/* input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color:red!important;
    color: fieldtext !important;
} */